import React, { ReactElement, useRef, useEffect, useState } from 'react';
import * as Styled from './styles';
import "./style.css"
import { useLocation } from '@reach/router';
import { ExitIntentPopupMobileV2 } from "./ExitIntentPopupMobileV2"; 
import { ExitIntentPopupSaleV2 } from "./ExitIntentPopupSaleV2"; 

export const ExitIntentPopupV2 = (): ReactElement => {
 
  
  return(<> <ExitIntentPopupSaleV2/>  <ExitIntentPopupMobileV2/></>)

  
};
